import { lazy, Suspense } from "react";

import "./styles.scss";

const { REACT_APP_HOME_URL = "" } = process.env;

const CollectionView = lazy(() => import("./components/CollectionViewer"));
const MapViewer = lazy(() => import("./components/MapViewer"));

const getProps = () => {
  const qs = new URLSearchParams(window.location.search);
  const props: Record<string, string> = {};
  qs.forEach((value, key) => {
    props[key] = value;
  });

  return props;
};

const AppSwitch = () => {
  const props: any = getProps();
  const path = window.location.pathname.replace(/^\//, "").split(/\//);

  switch (path[0]) {
    case "collection":
      const [, resourceId, view] = path;
      return <CollectionView resourceId={resourceId} view={view} {...props} />;
    case "map":
      const [, markers, options] = path;
      return <MapViewer markers={markers} options={options} {...props} />;
  }

  window.location.href = REACT_APP_HOME_URL;
  return <div></div>;
};

export const App = () => (
  <Suspense fallback={<div></div>}>
    <AppSwitch />
  </Suspense>
);

export default App;
